import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box, Card, CardContent, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';

const Background = styled(Box)({
    background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
});

function CreateMedicalStaffForm() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        document.title = 'Create Medical Staff Account'; // Set your desired title here
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccess('');

        try {
            const token = localStorage.getItem('token');

            const response = await axios.post(`${process.env.REACT_APP_API}/api/create-medical-staff`, {
                username, email, password
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            setSuccess(response.data.message);
            setOpenDialog(true); // Open the dialog when registration is successful
            setUsername('');
            setEmail('');
            setPassword('');
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error || 'Failed to create medical staff account');
            } else {
                setError('Failed to send request');
            }
            setOpenDialog(true); // Open the dialog for error message
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSuccess('');
        setError('');
    };

    return (
        <Background>
            <Card sx={{ width: '100%', maxWidth: 800, mt: 4 }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>Create Medical Staff Account</Typography>
                    <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                        Use the form below to create a new account for medical staff. Fill in the required fields and click "Create Account" to proceed.
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ mt: 2, backgroundColor: "#d258f1", color: "#fff", '&:hover': { backgroundColor: "#b847d9" } }}
                        >
                            Create Account
                        </Button>
                    </form>
                </CardContent>
            </Card>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle style={{ backgroundColor: '#d258f1', color: '#fff', textAlign: 'center' }}>
                    This is a very important message! Please do not skip!
                </DialogTitle>
                <DialogContent style={{ padding: '20px', textAlign: 'center' }}>
                    {success ? (
                        <Alert severity="success" sx={{ fontSize: '16px' }}>
                            {success.split('The unique ID is: ').map((part, index) => (
                                index === 1 ? (
                                    <>
                                        The unique ID is: <strong key={index} style={{ fontSize: '18px' }}>{part.split('.')[0]}</strong>.
                                        {part.substring(part.indexOf('.') + 1)}
                                    </>
                                ) : part
                            ))}
                        </Alert>
                    ) : (
                        <Alert severity="error" sx={{ fontSize: '16px' }}>{error}</Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Background>
    );
}

export default CreateMedicalStaffForm;
