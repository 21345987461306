import React, { useState, useEffect } from "react";
import {
  Typography, CircularProgress, Snackbar, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent, TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle, Alert
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import axios from "axios";

const Background = styled(Box)({
  background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '20px'
});

const CustomTableCell = styled(TableCell)({
  fontSize: '1.2rem', // Increased font size
  fontWeight: 'bold',
  color: '#333',
  padding: '24px', // Increased padding
});

const MedicalRecords = () => {
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    document.title = 'Medical Records';
    fetchMedicalRecords();
  }, []);

  const fetchMedicalRecords = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/get_patient_medical_record`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.status === 200) {
        setRecords(response.data);
        console.log(response.data)
        setFilteredRecords(response.data);
      } else {
        setError('Failed to fetch medical records');
      }
      setLoading(false);
    } catch (error) {
      setError('Failed to fetch medical records');
      setLoading(false);
    }
  };

  const handleViewRecord = async (url, filename) => {
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      window.open(urlObject, "_blank");
    } catch (error) {
      console.error("Error displaying medical record:", error);
      setError(`Failed to view medical record: ${error.message}`);
    }
  };

  const handleViewOriginalRecord = (uniqueId, filename) => {
    const url = `${process.env.REACT_APP_API}/api/view_original_medical_record/${uniqueId}/${encodeURIComponent(filename)}`;
    handleViewRecord(url, filename);
  };

  const handleViewBookmarkedRecord = (uniqueId, filename) => {
    const url = `${process.env.REACT_APP_API}/api/view_bookmarked_medical_record/${uniqueId}/${encodeURIComponent(filename)}`;
    handleViewRecord(url, filename);
  };

  const handleDownloadOriginalRecord = async (uniqueId, filename) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/view_original_medical_record/${uniqueId}/${encodeURIComponent(filename)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download original medical record');
    }
  };

  const handleDownloadBookmarkedRecord = async (uniqueId, filename) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/api/view_bookmarked_medical_record/${uniqueId}/${encodeURIComponent(filename)}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setError('Failed to download bookmarked medical record');
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setFilteredRecords(records);
    } else {
      setFilteredRecords(records.filter(record =>
        record.username.toLowerCase().includes(e.target.value.toLowerCase()) ||
        record.unique_id.toLowerCase().includes(e.target.value.toLowerCase()) ||
        formatDate(record.birth_date).toLowerCase().includes(e.target.value.toLowerCase())
      ));
    }
  };

  const handleOpenDialog = (record) => {
    setSelectedRecord(record);
    setSuccessMessage(`Patient information retrieved successfully`);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRecord(null);
    setSuccessMessage('');
    setErrorMessage('');
  };

  if (loading) return <CircularProgress />;
  if (error) return <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} message={error} />;

  return (
    <Background>
      <Card sx={{ width: '100%', maxWidth: 900, mt: 4 }}>
        <CardContent>
          <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>Medical Records</Typography>
          <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
            Below is a list of medical records available for viewing and download. Click on "View Medical Record" to view or "Download Medical Record" to download the file.
          </Typography>
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', mt: 6 }}>
            <TextField
              fullWidth
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{
                borderRadius: '25px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '25px',
                  '& fieldset': {
                    borderColor: '#d258f1',
                  },
                  '&:hover fieldset': {
                    borderColor: '#b23be0',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#d258f1',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#d258f1',
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#d258f1' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <TableContainer component={Paper} sx={{ padding: '24px', maxWidth: '100%', overflowX: 'auto' }}>
            <Table aria-label="medical records table">
              <TableHead>
                <TableRow>
                  <CustomTableCell>Name</CustomTableCell>
                  <CustomTableCell>MRN</CustomTableCell>
                  <CustomTableCell>Birth Date</CustomTableCell>
                  <CustomTableCell align="center">Original Record</CustomTableCell>
                  <CustomTableCell align="center">Bookmarked Record</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRecords.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: '24px', cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
                      onClick={() => handleOpenDialog(record)}
                    >
                      {record.username}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ padding: '24px' }}>
                      {record.unique_id}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ padding: '24px' }}>
                      {formatDate(record.birth_date)}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '24px' }}>
                      <a
                        href="#"
                        onClick={() => handleViewOriginalRecord(record.unique_id, record.medical_record_path)}
                        style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                      >
                        View Original
                      </a>
                      &nbsp;|&nbsp;
                      <a href="#"
                        onClick={() => handleDownloadOriginalRecord(record.unique_id, record.medical_record_path)}
                        style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                      >
                        Download Original
                      </a>
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '24px' }}>
                      <a
                        href="#"
                        onClick={() => handleViewBookmarkedRecord(record.unique_id, record.medical_record_path)}
                        style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                      >
                        View Bookmarked
                      </a>
                      &nbsp;|&nbsp;
                      <a href="#"
                        onClick={() => handleDownloadBookmarkedRecord(record.unique_id, record.medical_record_path)}
                        style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                      >
                        Download Bookmarked
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ backgroundColor: '#d258f1', color: '#fff', textAlign: 'center' }}>
          Patient Information
        </DialogTitle>
        <DialogContent style={{ padding: '20px'}}>
          {successMessage ? (
            <Alert severity="success" sx={{ fontSize: '16px' }}>
              <strong>Name:</strong> {selectedRecord?.username}<br />
              <strong>MRN:</strong> {selectedRecord?.unique_id}<br />
              <strong>Email:</strong> {selectedRecord?.email}<br />
              <strong>Birth Date:</strong> {formatDate(selectedRecord?.birth_date)}<br />
              <strong>CellPhone:</strong> {selectedRecord?.cellphone}<br />
              <strong>Address:</strong> {selectedRecord?.address}<br />
            </Alert>
          ) : (
            <Alert severity="error" sx={{ fontSize: '16px' }}>{errorMessage}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Background>
  );
};

export default MedicalRecords;
