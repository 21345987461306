import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Card } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { styled } from '@mui/system';

const StatCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px', // Reduce padding to make the box smaller
  margin: '10px',
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
  minWidth: '150px',
  minHeight: '150px', // Ensure the height is not too tall
});

const StatIcon = styled(Box)({
  color: '#d258f1',
  marginBottom: '5px', // Add space between icon and number
});

const StatNumber = styled(Typography)({
  fontSize: '1.5rem',
  color: '#d258f1', // Change the color of the number to purple
  marginBottom: '5px', // Add space between number and text
});

const StatText = styled(Typography)({
  fontSize: '1rem',
  color: '#d258f1',
});

const Statistics = () => {
  const [patientCount, setPatientCount] = useState(null);
  const [staffCount, setStaffCount] = useState(null);

  useEffect(() => {
    const fetchPatientCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/patient_count`, {
        //const response = await axios.get('http://localhost:7000/api/patient_count', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setPatientCount(response.data.patient_count);
      } catch (error) {
        console.error('Error fetching patient count:', error);
      }
    };

    const fetchStaffCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/staff_count`, {
        //const response = await axios.get('http://localhost:7000/api/staff_count', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setStaffCount(response.data.staff_count);
      } catch (error) {
        console.error('Error fetching staff count:', error);
      }
    };

    fetchPatientCount();
    fetchStaffCount();
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
      <StatCard>
        <StatIcon>
          <PersonAddIcon style={{ fontSize: '3rem' }} /> {/* Increase icon size */}
        </StatIcon>
        <StatNumber>
          {patientCount !== null ? patientCount : '...'}
        </StatNumber>
        <StatText>
          New Patients
        </StatText>
      </StatCard>
      <StatCard>
        <StatIcon>
          <SupervisorAccountIcon style={{ fontSize: '3rem' }} /> {/* Increase icon size */}
        </StatIcon>
        <StatNumber>
          {staffCount !== null ? staffCount : '...'}
        </StatNumber>
        <StatText>
          Available Staff
        </StatText>
      </StatCard>
    </Box>
  );
};

export default Statistics;
  