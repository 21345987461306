import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography, CircularProgress, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Paper, Snackbar, Alert, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';

function ManageProfiles() {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileSelected, setFileSelected] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [assigning, setAssigning] = useState(null); // Track which profile is being processed

  useEffect(() => {
    document.title = 'Manage Patient Profiles';

    const fetchProfiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/get_approved_patients`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        const formattedData = response.data.map(item => ({
          id: item[0],
          username: item[1],
          email: item[2],
          medical_record_path: item[4],
          bookmarked_medical_record_path: item[13],
          unique_id: item[11],
          birth_date: item[12] ? format(new Date(item[12]), 'yyyy-MM-dd') : ''
        }));
        setProfiles(formattedData);
        setFilteredProfiles(formattedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profiles:', error.response?.data?.error || 'Failed to fetch data');
        setError(error.response?.data?.error || 'Failed to fetch data');
        setLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    const filterProfiles = () => {
      const query = searchQuery.toLowerCase();
      const filtered = profiles.filter(profile =>
        profile.username.toLowerCase().includes(query) ||
        profile.email.toLowerCase().includes(query) ||
        profile.unique_id.toLowerCase().includes(query) ||
        profile.birth_date.includes(query)
      );
      setFilteredProfiles(filtered);
    };

    filterProfiles();
  }, [searchQuery, profiles]);

  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this profile?")) return;

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API}/api/delete_patient/${id}`, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.status === 200) {
        setProfiles(prevProfiles => prevProfiles.filter(profile => profile.id !== id));
        setFilteredProfiles(prevProfiles => prevProfiles.filter(profile => profile.id !== id));
        setSuccess("Patient deleted successfully.");
      } else {
        setError('Failed to delete profile: ' + response.data.error);
      }
    } catch (error) {
      console.error('Error deleting profile:', error);
      setError('Failed to delete profile: ' + (error.response?.data?.error || 'Unknown error'));
    }
  };

  const handleFileChange = (event, id) => {
    const file = event.target.files[0];
    const fileType = file.name.split('.').pop().toLowerCase();

    if (fileType !== 'pdf') {
      setError('You can only upload the medical record in PDF format.');
      setSelectedFile(null);
      setFileSelected(prevState => ({ ...prevState, [id]: false }));
      return;
    }

    setSelectedFile(file);
    setFileSelected(prevState => ({ ...prevState, [id]: true }));
    setSuccess("File selected successfully.");
  };

  const handleAssignMedicalRecord = async (uniqueId) => {
    if (!selectedFile) {
      setError('Please select a file to assign');
      return;
    }

    setAssigning(uniqueId); // Set the assigning state to the unique ID of the profile being processed

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      // Upload the file
      const uploadResponse = await axios.post(`${process.env.REACT_APP_API}/api/upload_medical_record`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      // Get file paths from response
      const originalFileName = uploadResponse.data.original_file_name;
      const bookmarkedFileName = uploadResponse.data.bookmarked_file_name;

      // Assign the file paths to the profile
      const assignResponse = await axios.put(`${process.env.REACT_APP_API}/api/assign_medical_record/${uniqueId}`, {
        medical_record_path: originalFileName,
        bookmarked_medical_record_path: bookmarkedFileName
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });

      if (assignResponse.status === 200) {
        setSuccess("Medical record updated successfully.");
        setProfiles(prevProfiles => prevProfiles.map(profile =>
          profile.unique_id === uniqueId
            ? { ...profile, medical_record_path: originalFileName, bookmarked_medical_record_path: bookmarkedFileName }
            : profile
        ));
        setFilteredProfiles(prevProfiles => prevProfiles.map(profile =>
          profile.unique_id === uniqueId
            ? { ...profile, medical_record_path: originalFileName, bookmarked_medical_record_path: bookmarkedFileName }
            : profile
        ));
        setSelectedFile(null);
        setFileSelected(prevState => ({ ...prevState, [uniqueId]: false }));
        setAssigning(null); // Reset the assigning state
      } else {
        setError('Failed to assign medical record: ' + (assignResponse.data.error || assignResponse.statusText));
        setAssigning(null); // Reset the assigning state
      }
    } catch (error) {
      console.error('Error assigning medical record:', error);
      setError('Failed to assign medical record: ' + (error.response?.data?.error || error.message));
      setAssigning(null); // Reset the assigning state
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(null);
    setError(null);
  };

  if (loading) return <Box display="flex" justifyContent="center" mt={2}><CircularProgress /></Box>;
  if (error) return <Box textAlign="center" mt={2}><Typography color="error">{error}</Typography></Box>;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
        Manage Patient Profiles
      </Typography>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
        <TextField
          variant="outlined"
          label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          sx={{
            borderRadius: '25px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
              '& fieldset': {
                borderColor: '#d258f1',
              },
              '&:hover fieldset': {
                borderColor: '#b23be0',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#d258f1',
              },
            },
            '& .MuiInputLabel-root': {
              color: '#d258f1',
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#d258f1' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {filteredProfiles.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Username</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>MRN</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Medical Record</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Birth Date</TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProfiles.map((profile) => (
                <TableRow key={profile.unique_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" align="center">
                    {profile.username}
                  </TableCell>
                  <TableCell align="center">{profile.email}</TableCell>
                  <TableCell align="center">{profile.unique_id}</TableCell>
                  <TableCell align="center">{profile.medical_record_path || '-'}</TableCell>
                  <TableCell align="center">{profile.birth_date}</TableCell>
                  <TableCell align="center">
                    {assigning === profile.unique_id ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          sx={{ mr: 1, bgcolor: '#d258f1', '&:hover': { bgcolor: '#b23be0' } }}
                        >
                          Upload Medical Record
                          <input
                            type="file"
                            hidden
                            onChange={(e) => handleFileChange(e, profile.unique_id)}
                          />
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAssignMedicalRecord(profile.unique_id)}
                          disabled={!fileSelected[profile.unique_id]}
                          sx={{ bgcolor: '#4caf50', '&:hover': { bgcolor: '#388e3c' } }}
                        >
                          Assign Record
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(profile.id)}
                          sx={{ ml: 1, bgcolor: '#f44336', '&:hover': { bgcolor: '#d32f2f' } }}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" align="center" mt={2}>No profiles found</Typography>
      )}
      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          {success}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ManageProfiles;
