import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Paper, Box, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import { enGB } from 'date-fns/locale'; // Import English locale

const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

const SignUpPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [address, setAddress] = useState("");   
  const [birthDate, setBirthDate] = useState(null); // Use null for initial state
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    document.title = 'Sign Up';
  }, []);

  const handleSignUp = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    if (!PASSWORD_REGEX.test(password)) {
      alert("Password must be at least 8 characters long, including an uppercase letter, a lowercase letter, a number, and a symbol.");
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/register`, {
        username,
        email,
        password,
        cellphone,
        address,
        birth_date: birthDate ? birthDate.toISOString().split('T')[0] : '', // Format birth date to yyyy-MM-dd
      });
      console.log(response.data);
      setSuccessMessage(response.data.message);  // Display the message with unique ID
      setOpenDialog(true); // Open the dialog when registration is successful
    } catch (error) {
      console.error("Error registering the user:", error.response.data);
      setErrorMessage("Error registering the user");
      setOpenDialog(true); // Open the dialog for error message
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
      <Paper elevation={3} style={{ padding: 40, width: 400, backgroundColor: '#fff', borderRadius: 8 }}>
        <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
          <img src="/assets/image.png" alt="YaiGlobal Logo" style={{ height: '50px', marginRight: '10px' }} />
          <Typography variant="h5" style={{ color: '#d258f1' }}>
            Sign Up
          </Typography>
        </Box>
        <form onSubmit={handleSignUp}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            InputLabelProps={{ style: { color: '#d258f1' } }}
            InputProps={{ style: { color: 'black' } }}
            sx={textFieldSx}
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputLabelProps={{ style: { color: '#d258f1' } }}
            InputProps={{ style: { color: 'black' } }}
            sx={textFieldSx}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputLabelProps={{ style: { color: '#d258f1' } }}
            InputProps={{ style: { color: 'black' } }}
            sx={textFieldSx}
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            InputLabelProps={{ style: { color: '#d258f1' } }}
            InputProps={{ style: { color: 'black' } }}
            sx={textFieldSx}
          />
          <TextField
            label="CellPhone"
            variant="outlined"
            fullWidth
            margin="normal"
            value={cellphone}
            onChange={(e) => setCellphone(e.target.value)}
            InputLabelProps={{ style: { color: '#d258f1' } }}
            InputProps={{ style: { color: 'black' } }}
            sx={textFieldSx}
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            InputLabelProps={{ style: { color: '#d258f1' } }}
            InputProps={{ style: { color: 'black' } }}
            sx={textFieldSx}
          />
          <DatePicker
            selected={birthDate}
            onChange={(date) => setBirthDate(date)}
            dateFormat="yyyy-MM-dd"
            locale={enGB} // Use English locale
            customInput={
              <TextField 
                label="Birth Date" 
                variant="outlined" 
                fullWidth 
                margin="normal" 
                required 
                InputLabelProps={{ style: { color: '#d258f1' } }} 
                InputProps={{ style: { color: 'black' } }} 
                sx={textFieldSx} 
              />
            }
            wrapperClassName="datepicker-wrapper" // Ensure it takes full width
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              bgcolor: '#d258f1',
              '&:hover': {
                bgcolor: '#c74dea',
              },
            }}
          >
            Sign Up
          </Button>
        </form>
        <Typography style={{ marginTop: 20, textAlign: 'center', color: '#d258f1' }}>
          Already have an account? <Link to="/" style={{ color: '#d258f1' }}>Login</Link>
        </Typography>
      </Paper>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ backgroundColor: '#d258f1', color: '#fff', textAlign: 'center' }}>
          This is a very important message! Please read until the end and do not skip!
        </DialogTitle>
        <DialogContent style={{ padding: '20px', textAlign: 'center' }}>
          {successMessage ? (
            <Alert severity="success" sx={{ fontSize: '16px' }}>
              {successMessage.split('Your MRN is: ').map((part, index) => (
                index === 1 ? (
                  <>
                    Your MRN is: <strong key={index} style={{ fontSize: '18px' }}>{part.split('.')[0]}</strong>.
                    {part.substring(part.indexOf('.') + 1)}
                  </>
                ) : part
              ))}
            </Alert>
          ) : (
            <Alert severity="error" sx={{ fontSize: '16px' }}>{errorMessage}</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const textFieldSx = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0,0,0,0.23)',
    },
    '&:hover fieldset': {
      borderColor: '#d258f1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#d258f1',
    },
  },
};

export default SignUpPage;
