import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Paper, CircularProgress, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

function RecentRequests() {
    const [userRequests, setUserRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        document.title = 'Recent Requests';

        const fetchUserRequests = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/api/recent_requests`, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                });
                const formattedData = response.data.map(item => ({
                    unique_id: item[0],
                    username: item[1],
                    email: item[2],
                    cellphone: item[5],
                    added_at: item[6],
                    status: item[8]
                }));
                setUserRequests(formattedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user profiles:', error.response?.data?.error || 'Failed to fetch data');
                setError(error.response?.data?.error || 'Failed to fetch data');
                setLoading(false);
            }
        };

        fetchUserRequests();
    }, []);

    const handleApprove = async (userId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/approve_user/${userId}`, {}, {
                headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
            });

            if (response.status === 200) {
                setUserRequests(prevRequests => prevRequests.filter(user => user.unique_id !== userId));
                setSuccessMessage('Patient is now approved');
            } else {
                console.error('Error approving user:', response.data.error);
            }
        } catch (error) {
            console.error('Network error or other issue:', error.toString());
        }
    };

    const handleCloseSnackbar = () => {
        setSuccessMessage('');
    };

    if (loading) return <Box display="flex" justifyContent="center" mt={2}><CircularProgress /></Box>;
    if (error) return <Box textAlign="center" mt={2}><Typography color="error">{error}</Typography></Box>;

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                Recent Requests
            </Typography>
            {userRequests.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Phone</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                <TableCell align="center" sx={{ fontWeight: 'bold' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {userRequests.map((user) => (
                                <TableRow key={user.unique_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" align="center">
                                        {user.username}
                                    </TableCell>
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">{user.cellphone || 'N/A'}</TableCell>
                                    <TableCell align="center">{user.status}</TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => handleApprove(user.unique_id)} variant="contained" sx={{ bgcolor: "#d258f1", '&:hover': { backgroundColor: '#b23be0' } }}>
                                            Approve
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : <Typography textAlign="center">No pending requests.</Typography>}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default RecentRequests;
