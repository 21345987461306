import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, Box, Card, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';
import HealingIcon from '@mui/icons-material/Healing';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Statistics from './Statistics'; 
import PatientDistributionMap from './PatientDistributionMap'; 

const Background = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f7f7f7' // Light grey background
});

const WelcomeCard = styled(Card)({
  width: '100%',
  maxWidth: '1000px',
  marginTop: '40px',
  marginBottom: '20px',
  padding: '20px',
  textAlign: 'center',
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  position: 'relative'
});

const TipsCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  marginBottom: '20px',
  padding: '20px',
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  border: `2px solid ${'#d258f1'}`,
  borderRadius: '10px',
  marginLeft: '20px',
  marginRight: '20px'
}));

const TipCard = styled(Card)(({ theme }) => ({
  padding: '20px',
  margin: '10px',
  textAlign: 'center',
  border: `1px solid ${'#d258f1'}`,
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  background: 'rgba(255, 255, 255, 1)',
  borderRadius: '10px',
  flex: '1',
  minWidth: '150px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: 'rgba(210, 88, 241, 0.1)',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#000000'
}));

const TipsGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
});

const WelcomeImage = styled('img')({
  position: 'absolute',
  top: '-40px',
  right: '-50px',
  width: '150px',
  height: '150px',
});

const PatientDashboard = () => {
  const [healthTips, setHealthTips] = useState('');
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const [isApproved, setIsApproved] = useState(false); // Track if patient is approved

  useEffect(() => {
    const fetchHealthTips = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/generate_health_tips`, {
       // const response = await axios.get('http://localhost:7000/api/generate_health_tips', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setHealthTips(response.data.response);
        setIsApproved(true); // Set to true if health tips are successfully fetched
      } catch (error) {
        console.error('Error fetching health tips:', error);
        setIsApproved(false); // Set to false if there was an error fetching health tips
      } finally {
        setLoading(false);
      }
    };

    const fetchUsername = async () => {
      try {

        const response = await axios.get(`${process.env.REACT_APP_API}/api/get_current_patient_username`, {
        //const response = await axios.get('http://localhost:7000/api/get_current_patient_username', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUsername(response.data.username);
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchHealthTips();
    fetchUsername();
  }, []);

  const selectRandomTips = (tipsText) => {
    const tipsArray = tipsText.split('\n\n');
    const sentences = [];

    tipsArray.forEach(tip => {
      if (!tip.startsWith('**') && !tip.endsWith(':**')) {
        const cleanTip = tip.replace(/\n\*/g, '').trim(); // Removing bullet points and trimming
        if (cleanTip) {
          // Split the clean tip into sentences and add to the sentences array
          const tipSentences = cleanTip.split('.').map(sentence => sentence.trim()).filter(sentence => sentence.length > 1); // Ensure each sentence is at least one word long
          sentences.push(...tipSentences);
        }
      }
    });

    const uniqueSentences = [...new Set(sentences.map(sentence => sentence.trim()).filter(sentence => sentence && sentence !== ''))]; // Ensure unique sentences

    const selectedTips = [];
    for (let i = 0; i < 3 && uniqueSentences.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * uniqueSentences.length);
      selectedTips.push(uniqueSentences[randomIndex] + '.');
      uniqueSentences.splice(randomIndex, 1);
    }
    return selectedTips;
  };

  const displayedTips = selectRandomTips(healthTips);
  const icons = [<HealingIcon style={{ color: '#d258f1' }} />, <FavoriteIcon style={{ color: '#d258f1' }} />, <LocalHospitalIcon style={{ color: '#d258f1' }} />];

  // Set the document title when the component mounts
  useEffect(() => {
    document.title = `Patient Dashboard - ${username}`; 
  }, [username]); // Update title when username changes

  return (
    <Background>
      <WelcomeCard>
        <WelcomeImage src="/assets/med.png" alt="Welcome" />
        <Typography variant="h3" gutterBottom style={{ color: '#d258f1' }}>
          Welcome to Your Dashboard, {username}!
        </Typography>
        <Typography variant="body1" style={{ color: '#000000' }}>
          We're here to help you stay healthy. Check out your personalized health tips below!
        </Typography>
      </WelcomeCard>

      <Statistics />

      <TipsCard>
        <Typography variant="h5" gutterBottom style={{ color: '#d258f1', textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          Health Tips
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : isApproved ? (
          <TipsGrid>
            {displayedTips.map((tip, index) => (
              <TipCard key={index}>
                {icons[index % icons.length]}
                <Typography variant="body1" style={{ color: '#000000' }}>{tip}</Typography>
              </TipCard>
            ))}
          </TipsGrid>
        ) : (
          <Typography variant="body1" style={{ color: '#000000', textAlign: 'center', marginTop: '20px' }}>
            Personalized health tips are not available yet. Once you are assigned a medical record, tips will be generated for you.
          </Typography>
        )}
      </TipsCard>

      <PatientDistributionMap />

      {/* Other sections of the patient dashboard can be added here */}
    </Background>
  );
};

export default PatientDashboard;
