import { useEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Box, Typography, useTheme, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ role }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState('Dashboard');
  const [uniqueId, setUniqueId] = useState(null);

  useEffect(() => {
    const fetchPatientId = async () => {
      if (role === 'patient') {
        try {
          // Assuming you store the JWT token in localStorage
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.REACT_APP_API}/api/get_patient_id`, {
            headers: { Authorization: `Bearer ${token}` } // Include token in headers
          });
          console.log('API response:', response.data);
          setUniqueId(response.data.unique_id);
        } catch (error) {
          console.error('Error fetching patient ID:', error);
        }
      }
    };

    fetchPatientId();
  }, [role]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.primary[400],
        '& .pro-sidebar': {
          height: '100%',
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-sidebar-inner': {
          background: `${colors.primary[400]} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: '#868dfb !important',
        },
        '& .pro-menu-item.active': {
          color: '#6870fa !important',
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: '10px 0 20px 0', color: colors.grey[100] }}
          >
            {!isCollapsed && (
              <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
                <Typography variant="h3" color={colors.grey[100]}>
                  {role === 'admin' ? 'ADMINS' : role === 'medical_staff' ? 'STAFF' : 'PATIENT'}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={
                    role === 'admin'
                      ? '../../assets/user.png'
                      : role === 'medical_staff'
                      ? '../../assets/imageStaff.png'
                      : role === 'patient'
                      ? '../../assets/pt.png'
                      : '../../assets/imagePatient.png'
                  }
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
              </Box>
              <Box textAlign="center">
                <Typography variant="h2" color="#d258f1" fontWeight="bold" sx={{ m: '10px 0 0 0' }}>
                  Yai4Health
                </Typography>
                <Typography variant="h5" color={colors.grey[100]}>
                  {role === 'admin' ? 'Admin' : role === 'medical_staff' ? 'Medical Staff' : 'Patient'}
                </Typography>
                {role === 'patient' && uniqueId && (
                  <Typography variant="body2"  color="#d258f1" sx={{ mt: 1, fontSize: '1.2rem' }}>
                     {uniqueId}
                  </Typography>
                )}
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : '10%'}>
            {role === 'admin' && (
              <>
                <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                  Data Analysis
                </Typography>
                <Item
                  title="Dashboard"
                  to="/dashboard"
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Typography variant="h6" color={colors.grey[300]} sx={{ m: '15px 0 5px 20px' }}>
                  Manage Information
                </Typography>
                <Item
                  title="Manage Patients Profiles"
                  to="/manage_profiles"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Recent Requests"
                  to="/recent_requests"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Staff Profile Form"
                  to="/create-medical-staff"
                  icon={<PersonOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

            {role === 'medical_staff' && (
              <>
                <Item
                  title="Dashboard"
                  to="/medical-staff-dashboard"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage profile"
                  to="/manage_profile"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Patients"
                  to="/medical_records"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

            {role === 'patient' && (
              <>
                <Item
                  title="Dashboard"
                  to="/patient-dashboard"
                  icon={<PeopleOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Profile"
                  to="/patient_manage_profile"
                  icon={<PersonOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Medical Record"
                  to="/patient_medical_record"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
