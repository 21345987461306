import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Logout icon
import { useNavigate } from 'react-router-dom';

function LogoutButton() {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear session and local storage
        sessionStorage.clear();
        localStorage.clear();
        
        // Redirect to login page
        navigate('/login');
    };

    return (
        <Button
          onClick={handleLogout}
          variant="contained"
          color="secondary"
          startIcon={<ExitToAppIcon />}
          sx={{
            backgroundColor: "#d258f1",
            color: 'white',
            '&:hover': {
              backgroundColor: '#a18cc9',
            },
            ml: 2,
          }}
        >
          Logout
        </Button>
    );
}

export default LogoutButton;
