import { Box, useTheme } from "@mui/material";
import LogoutButton from '../../component/LogoutButton';

const Topbar = () => {
  const theme = useTheme();

  return (
    <Box 
      display="flex" 
      justifyContent="flex-end" 
      p={2} 
      position="absolute" 
      top={0} 
      left={0} 
      right={0} 
      backgroundColor="transparent"
    >
      {/* ICONS */}
      <Box display="flex" alignItems="center">
        <LogoutButton />
      </Box>
    </Box>
  );
};

export default Topbar;
