import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {jwtDecode} from 'jwt-decode'; // Make sure to install jwt-decode
import { ColorModeContext, useMode } from './theme';

// Import components
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import SignInForm from './component/SignInForm';
import SignUpPage from './component/SignUpForm';
import MedicalStaffDashboard from './component/MedicalStaffDashboard';
import CreateMedicalStaffForm from './component/CreateMedicalStaffForm';
import RecentRequests from './component/RecentRequests';
import ManageProfiles from './component/ManageProfiles';
import ManageProfile from './component/ManageProfile';
import Chatbot from './component/Chatbot';
import MedicalRecords from './component/MedicalRecords';
import PatientDashboard from './component/PatientDashboard';
import PatientManageProfile from './component/PatientManageProfile'; 
import PatientMedicalRecord from './component/PatientMedicalRecord'; 

const App = () => {
  const [theme, colorMode] = useMode();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [role, setRole] = useState('');

  // Hide sidebar on specific routes
  useEffect(() => {
    const hideSidebarOnRoutes = ['/login', '/signup'];
    setIsSidebarVisible(!hideSidebarOnRoutes.includes(location.pathname));
  }, [location]);

  useEffect(() => {
    // Check authentication status and role
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const { exp } = decodedToken;
        const currentTime = Date.now() / 1000;

        if (exp < currentTime) {
          // Token is expired
          sessionStorage.clear();
          localStorage.clear();
          navigate('/login');
        } else {
          // Token is valid
          const storedRole = sessionStorage.getItem('userRole') || localStorage.getItem('userRole');
          setRole(storedRole || '');

          // Redirect based on role if trying to access login or a restricted page
          if (location.pathname === '/login') {
            switch (storedRole) {
              case 'admin':
                navigate('/dashboard');
                break;
              case 'medical_staff':
                navigate('/medical-staff-dashboard');
                break;
              case 'patient':
                navigate('/patient-dashboard');
                break;
              default:
                navigate('/login');
            }
          }
        }
      } catch (error) {
        console.error('Token decoding error:', error);
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
      }
    } else {
      // No token found
      if (location.pathname !== '/login' && location.pathname !== '/signup') {
        navigate('/login');
      }
    }
  }, [location, navigate]);

  const ProtectedRoute = ({ element, allowedRoles }) => {
    if (!role) {
      return <Navigate to="/login" replace />;
    }

    if (!allowedRoles.includes(role)) {
      switch (role) {
        case 'admin':
          return <Navigate to="/dashboard" replace />;
        case 'medical_staff':
          return <Navigate to="/medical-staff-dashboard" replace />;
        case 'patient':
          return <Navigate to="/patient-dashboard" replace />;
        default:
          return <Navigate to="/login" replace />;
      }
    }

    return element;
  };

    

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ display: 'flex', minHeight: '100vh' }}>
          {isSidebarVisible && <Sidebar role={role} />}
          <main style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {isSidebarVisible && <Topbar />}
            <div style={{ flexGrow: 1 }}>
              <Routes>
                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route path="/login" element={<SignInForm setRole={setRole} />} />
                <Route path="/signup" element={<SignUpPage />} />

                {/* Protected Routes */}
                <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} allowedRoles={['admin']} />} />
                <Route path="/medical-staff-dashboard" element={<ProtectedRoute element={<MedicalStaffDashboard />} allowedRoles={['medical_staff']} />} />
                <Route path="/patient-dashboard" element={<ProtectedRoute element={<PatientDashboard />} allowedRoles={['patient']} />} />
                <Route path="/create-medical-staff" element={<ProtectedRoute element={<CreateMedicalStaffForm />} allowedRoles={['admin']} />} />
                <Route path="/recent_requests" element={<ProtectedRoute element={<RecentRequests />} allowedRoles={['admin']} />} />
                <Route path="/manage_profiles" element={<ProtectedRoute element={<ManageProfiles />} allowedRoles={['admin']} />} />
                <Route path="/manage_profile" element={<ProtectedRoute element={<ManageProfile />} allowedRoles={['medical_staff']} />} />
                <Route path="/medical_records" element={<ProtectedRoute element={<MedicalRecords />} allowedRoles={['medical_staff']} />} />
                <Route path="/patient_medical_record" element={<ProtectedRoute element={<PatientMedicalRecord />} allowedRoles={['patient']} />} />
                <Route path="/patient_manage_profile" element={<ProtectedRoute element={<PatientManageProfile />} allowedRoles={['patient']} />} />

                {/* Catch-all route */}
                <Route path="*" element={<Navigate replace to="/login" />} />
              </Routes>
            </div>
            {isSidebarVisible && <Chatbot />}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
