import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Paper, Box } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Ensure correct import

function SignInForm({ setRole }) {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Sign In';
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API}/api/login`, {
        identifier,
        password
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      const { access_token } = response.data;
      sessionStorage.setItem('token', access_token);  // Store in sessionStorage
      localStorage.setItem('token', access_token);    // Store in localStorage
      console.log('Login successful:', response.data);
  
      // Decode the JWT to get user role
      const decodedToken = jwtDecode(access_token);
      const { role } = decodedToken;
  
      // Save role to sessionStorage and localStorage
      sessionStorage.setItem('userRole', role);
      localStorage.setItem('userRole', role);
      setRole(role); // Update role in App component
  
      // Redirect based on role
      if (role === 'admin') {
        navigate('/dashboard');
      } else if (role === 'medical_staff') {
        navigate('/medical-staff-dashboard');
      } else if (role === 'patient') {
        navigate('/patient-dashboard');
      }
    } catch (error) {
      if (error.response) {
        console.error('Login error:', error.response.data);
        setError(error.response.data.error || 'Login failed');
      } else if (error.request) {
        console.error('Login error:', error.request);
        setError('No response from server');
      } else {
        console.error('Error:', error.message);
        setError('Login failed due to technical issues');
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
      <Paper elevation={3} style={{ padding: 40, width: 400, backgroundColor: '#fff', borderRadius: 8 }}>
        <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
          <img src="/assets/image.png" alt="YaiGlobal Logo" style={{ height: '50px', marginRight: '10px' }} />
          <Typography variant="h5" style={{ color: '#5b1a89' }}>
            Login
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            required
            InputLabelProps={{
              style: { color: '#5b1a89' },
            }}
            InputProps={{
              style: { color: 'black' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0,0,0,0.23)',
                },
                '&:hover fieldset': {
                  borderColor: '#5b1a89',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d258f1',
                },
              },
            }}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputLabelProps={{
              style: { color: '#5b1a89' },
            }}
            InputProps={{
              style: { color: 'black' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(0,0,0,0.23)',
                },
                '&:hover fieldset': {
                  borderColor: '#5b1a89',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#d258f1',
                },
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              mt: 2,
              bgcolor: '#d258f1',
              '&:hover': {
                bgcolor: '#c74dea',
              },
            }}
          >
            Login
          </Button>
        </form>
        {error && (
          <Typography color="error" style={{ marginTop: 20, textAlign: 'center' }}>
            {error}
          </Typography>
        )}
        <Typography style={{ marginTop: 20, textAlign: 'center', color: '#d258f1' }}>
          Don't have an account? <Link to="/signup" style={{ color: '#d258f1' }}>Sign Up</Link>
        </Typography>
      </Paper>
    </div>
  );
}

export default SignInForm;
