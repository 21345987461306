import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, Card } from '@mui/material';
import { styled } from '@mui/system';
import Statistics from './Statistics'; // Import the Statistics component
import PatientDistributionMap from './PatientDistributionMap'; // Import the PatientDistributionMap component
import AgeDistribution from './AgeDistribution'; // Import the AgeDistribution component

const Background = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f7f7f7' // Light grey background
});

const WelcomeCard = styled(Card)({
  width: '100%',
  maxWidth: '1000px',
  marginTop: '40px',
  marginBottom: '20px',
  padding: '20px',
  textAlign: 'center',
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  position: 'relative'
});

const WelcomeImage = styled('img')({
  position: 'absolute',
  top: '-40px',
  right: '-50px',
  width: '150px',
  height: '150px',
});

const FlexContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  maxWidth: '1200px',
  marginTop: '20px',
});

const MedicalStaffDashboard = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    document.title = 'Medical Staff Dashboard'; // Set your desired title here

    const fetchUsername = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/get_current_staff_username`, {
        //const response = await axios.get('http://localhost:7000/api/get_current_staff_username', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setUsername(response.data.username);
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchUsername();
  }, []);

  return (
    <Background>
      <WelcomeCard>
        <WelcomeImage src="/assets/med.png" alt="Welcome" />
        <Typography variant="h3" gutterBottom style={{ color: '#d258f1' }}>
          Welcome to Your Dashboard, {username}!
        </Typography>
        <Typography variant="body1" style={{ color: '#000000' }}>
          We're here to help you manage patient health effectively.
        </Typography>
      </WelcomeCard>

      <Statistics />
      
      <FlexContainer>
        <AgeDistribution /> {/* Age Distribution component */}
        <PatientDistributionMap /> {/* Patient Distribution Map component */}
      </FlexContainer>

      {/* Other sections of the medical staff dashboard can be added here */}
    </Background>
  );
};

export default MedicalStaffDashboard;
