import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Grid, Snackbar, CircularProgress, Avatar, Box, Card, CardContent, Alert } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Background = styled(Box)({
    background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px'
});

const ManageProfile = () => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        document.title = 'Manage Profile'; 

        fetchProfile();
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/get_medical_staff_profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setProfile({
                    id: data.id,
                    username: data.username,
                    email: data.email,
                    cellphone: data.cellphone,
                    address: data.address,
                    age: data.age
                });
            } else {
                setError('Failed to fetch profile');
            }
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch profile');
            setLoading(false);
        }
    };

    const handleUpdateProfile = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/update_medical_staff_profile`, profile, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setSuccessMessage('Profile updated successfully');
            } else {
                setError('Failed to update profile');
            }
        } catch (error) {
            setError('Failed to update profile');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleCloseSnackbar = () => {
        setSuccessMessage('');
        setError('');
    };

    if (loading) return <CircularProgress />;

    return (
        <Background>
            <Card sx={{ width: '100%', maxWidth: 500, mt: 4 }}>
                <CardContent>
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
                            <Avatar sx={{ width: 80, height: 80, mb: 2 }}>
                                <AccountCircleIcon sx={{ width: 80, height: 80 }} />
                            </Avatar>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                            <Typography variant="h4" sx={{ mb: 2 }}>Manage Profile</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                            <Typography variant="body1">
                                Welcome to your profile management page.
                                Please fill-in the missing information and ensure your details are up-to-date.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="username"
                                label="Username"
                                value={profile.username || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="email"
                                label="Email"
                                value={profile.email || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="cellphone"
                                label="Cellphone"
                                value={profile.cellphone || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="address"
                                label="Address"
                                value={profile.address || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="age"
                                label="Age"
                                value={profile.age || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button variant="contained" sx={{ fontSize: 16, backgroundColor: "#d258f1", '&:hover': { backgroundColor: "#b847d9" }, px: 3 }} onClick={handleUpdateProfile}>
                                Update Profile
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Background>
    );
};

export default ManageProfile;
