import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from "recharts";

const ChartContainer = styled(Box)({
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  padding: "20px",
  width: "45%",
  maxWidth: "450px",
  textAlign: "center",
});

const COLORS = ["#d258f1", "#5b1a89", "#8a2be2", "#a020f0", "#ba55d3"];

const AgeDistribution = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
       const response = await axios.get(`${process.env.REACT_APP_API}/api/get_age_distribution`, {
        //const response = await axios.get("http://localhost:7000/api/get_age_distribution" , {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching age distribution data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <Typography variant="h6">No data available</Typography>;
  }

  return (
    <ChartContainer>
      <Typography variant="h5" gutterBottom style={{ color: "#d258f1", fontWeight: "bold", fontSize: "1.5rem" }}>
        Patient Age Distribution
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="ageRange"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default AgeDistribution;
