import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Grid, Snackbar, CircularProgress, Avatar, Box, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Background = styled(Box)({
    background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px'
});

const PatientManageProfile = () => {
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    useEffect(() => {
        document.title = 'Manage Profile';
    }, []);

    const fetchProfile = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/get_patient_profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                const data = response.data;
                setProfile({
                    id: data.id,
                    username: data.username,
                    email: data.email,
                    cellphone: data.cellphone
                });
            } else {
                setError('Failed to fetch profile');
            }
            setLoading(false);
        } catch (error) {
            setError('Failed to fetch profile');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleUpdateProfile = async () => {
        try {
            const { id, ...profileData } = profile; // Exclude unique_id from update
            const response = await axios.post(`${process.env.REACT_APP_API}/api/update_patient_profile`, profileData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setSuccessMessage('Profile updated successfully');
            } else {
                setError('Failed to update profile');
            }
        } catch (error) {
            setError('Failed to update profile');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
    };

    if (loading) return <CircularProgress />;
    if (error) return <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} message={error} />;
    if (successMessage) return <Snackbar open={true} autoHideDuration={6000} onClose={() => setSuccessMessage(null)} message={successMessage} />;

    return (
        <Background>
            <Card sx={{ width: '100%', maxWidth: 500, mt: 4 }}>
                <CardContent>
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item xs={12} sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
                            <Avatar sx={{ width: 80, height: 80, mb: 2 }}>
                                <AccountCircleIcon sx={{ width: 80, height: 80 }} />
                            </Avatar>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                            <Typography variant="h4" sx={{ mb: 2 }}>Manage Profile</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                            <Typography variant="body1">
                                Welcome to your profile management page. Here, you can update your username, email, and cellphone number.
                                Please ensure your details are up-to-date.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="id"
                                label="Unique ID"
                                value={profile.id || ''}
                                disabled
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="username"
                                label="Username"
                                value={profile.username || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="email"
                                label="Email"
                                value={profile.email || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                name="cellphone"
                                label="Cellphone"
                                value={profile.cellphone || ''}
                                onChange={handleChange}
                                sx={{ mb: 3 }}
                                InputLabelProps={{ style: { fontSize: 16 } }}
                                InputProps={{ style: { fontSize: 16 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button variant="contained" sx={{ fontSize: 16, backgroundColor: "#d258f1", '&:hover': { backgroundColor: "#b847d9" }, px: 3 }} onClick={handleUpdateProfile}>
                                Update Profile
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Background>
    );
};

export default PatientManageProfile;
