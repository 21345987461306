import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress, Snackbar, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const Background = styled(Box)({
    background: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px'
});

const CustomTableCell = styled(TableCell)({
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#333'
});

const PatientMedicalRecord = () => {
    const [originalRecord, setOriginalRecord] = useState(null);
    const [bookmarkedRecord, setBookmarkedRecord] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = 'Patient Medical Record'; // Set your desired title here
        fetchMedicalRecords();
    }, []);

    const fetchMedicalRecords = async () => {
        try {
            const [originalResponse, bookmarkedResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API}/api/get_patient_original_medical_record`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }),
                axios.get(`${process.env.REACT_APP_API}/api/get_patient_bookmarked_medical_record`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                })
            ]);

            if (originalResponse.status === 200) {
                setOriginalRecord(originalResponse.data.medical_record_path);
            } else {
                setError('Failed to fetch original medical record');
            }

            if (bookmarkedResponse.status === 200) {
                setBookmarkedRecord(bookmarkedResponse.data.bookmarked_medical_record_path);
            } else {
                setError('Failed to fetch bookmarked medical record');
            }

            setLoading(false);
        } catch (error) {
            setError('Failed to fetch medical records');
            setLoading(false);
        }
    };

    const handleViewRecord = async (filename, type) => {
        try {
            const apiUrl = type === 'original' 
                ? `${process.env.REACT_APP_API}/api/view_patient_original_medical_record/${encodeURIComponent(filename)}`
                : `${process.env.REACT_APP_API}/api/view_patient_bookmarked_medical_record/${encodeURIComponent(filename)}`;
            
            const response = await fetch(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            window.open(url, "_blank");
        } catch (error) {
            console.error("Error displaying medical record:", error);
            setError(`Failed to view medical record: ${error.message}`);
        }
    };

    const handleDownloadRecord = async (filename, type) => {
        try {
            const apiUrl = type === 'original'
                ? `${process.env.REACT_APP_API}/api/view_patient_original_medical_record/${encodeURIComponent(filename)}`
                : `${process.env.REACT_APP_API}/api/view_patient_bookmarked_medical_record/${encodeURIComponent(filename)}`;
            
            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                responseType: 'blob' // Ensure the response is treated as a binary file
            });

            // Create a URL for the PDF and trigger download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            setError('Failed to download medical record');
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Snackbar open={true} autoHideDuration={6000} onClose={() => setError(null)} message={error} />;

    return (
        <Background>
            <Card sx={{ width: '100%', maxWidth: 800, mt: 4 }}>
                <CardContent>
                    <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>Patient Medical Record</Typography>
                    <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
                        Below are your medical records available for viewing and download. Click on "View Medical Record" to view or "Download Medical Record" to download the file.
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table aria-label="medical record table">
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell align="center">Record Type</CustomTableCell>
                                    <CustomTableCell align="center">Actions</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {originalRecord ? (
                                    <TableRow>
                                        <TableCell align="center">Original Medical Record</TableCell>
                                        <TableCell align="center">
                                            <a
                                                href="#"
                                                onClick={() => handleViewRecord(originalRecord, 'original')}
                                                style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                                            >
                                                View Medical Record
                                            </a>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#d258f1', '&:hover': { backgroundColor: '#b847d9' }, ml: 2 }}
                                                onClick={() => handleDownloadRecord(originalRecord, 'original')}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">
                                            No original medical record available
                                        </TableCell>
                                    </TableRow>
                                )}
                                {bookmarkedRecord ? (
                                    <TableRow>
                                        <TableCell align="center">Bookmarked Medical Record</TableCell>
                                        <TableCell align="center">
                                            <a
                                                href="#"
                                                onClick={() => handleViewRecord(bookmarkedRecord, 'bookmarked')}
                                                style={{ textDecoration: 'underline', color: '#1976d2', cursor: 'pointer' }}
                                            >
                                                View Bookmarked Medical Record
                                            </a>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#d258f1', '&:hover': { backgroundColor: '#b847d9' }, ml: 2 }}
                                                onClick={() => handleDownloadRecord(bookmarkedRecord, 'bookmarked')}
                                            >
                                                Download
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2} align="center">
                                            No bookmarked medical record available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Background>
    );
};

export default PatientMedicalRecord;
