import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Statistics from '../../component/Statistics';
import PatientDistributionMap from '../../component/PatientDistributionMap';
import AgeDistribution from '../../component/AgeDistribution';

const Background = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  backgroundColor: '#f7f7f7'
});

const WelcomeCard = styled(Box)({
  width: '100%',
  maxWidth: '1000px',
  marginTop: '40px',
  marginBottom: '20px',
  padding: '20px',
  textAlign: 'center',
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  position: 'relative',
  borderRadius: '15px',
});

const ChartContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  maxWidth: '1000px',
  marginTop: '20px',
  marginBottom: '20px',
});

const AdminDashboard = () => {
  useEffect(() => {
    document.title = 'Admin Dashboard'; // Set your desired title here
  }, []);

  return (
    <Background>
      <WelcomeCard>
        <Typography variant="h3" gutterBottom style={{ color: '#d258f1' }}>
          Welcome to Your Dashboard, Admin!
        </Typography>
        <Typography variant="body1" style={{ color: '#000000' }}>
          Manage and oversee all patient and staff activities effectively.
        </Typography>
      </WelcomeCard>

      <Statistics />

      <ChartContainer>
        <AgeDistribution />
        <PatientDistributionMap />
      </ChartContainer>

      {/* Other sections of the admin dashboard can be added here */}
    </Background>
  );
};

export default AdminDashboard;
