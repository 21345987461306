import React, { useState } from 'react';
import axios from 'axios';
import { Fab, Box, TextField, Button, List, ListItem, ListItemText, Paper, Avatar } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import RobotIcon from '@mui/icons-material/Android';

function Chatbot() {
    const [input, setInput] = useState('');
    const [image, setImage] = useState(null);
    const [messages, setMessages] = useState([]);
    const [open, setOpen] = useState(false);

    const handleInputChange = (event) => setInput(event.target.value);
    const handleImageChange = (event) => setImage(event.target.files[0]);
    const toggleChat = () => setOpen(!open);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!input.trim() && !image) return;
    
        const newMessage = { text: input, sender: 'user' };
        setMessages(messages => [...messages, newMessage]);
        setInput('');
    
        const formData = new FormData();
        formData.append('input_text', input);
        if (image) formData.append('image', image);
    
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/process_question`, formData, {
            //const response = await axios.post('http://localhost:7000/api/process_question', formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            let botResponse = "Default response";
            if (response.data && response.data.response) {
                botResponse = typeof response.data.response === 'string' ? response.data.response : "No specific answer available based on the input.";
            }
    
            setMessages(messages => [...messages, { text: botResponse, sender: 'bot' }]);
        } catch (error) {
            console.error('Error when sending question:', error);
    
            let errorMessage = 'Failed to get an answer.';
            if (error.response) {
                if (error.response.status === 403) {
                    errorMessage = error.response.data.response || 'For data security and to ensure patient privacy, we cannot answer that question.';
                } else if (error.response.status === 400) {
                    errorMessage = error.response.data.response || 'No medical record found for this patient.';
                }
            }
    
            setMessages(messages => [...messages, { text: errorMessage, sender: 'bot' }]);
        } finally {
            setImage(null);
        }
    };
    
    
    return (
        <div>
            <Fab style={{ position: 'fixed', bottom: 20, right: 20, backgroundColor: '#d258f1', color: '#fff' }} onClick={toggleChat}>
                <ChatIcon />
            </Fab>

            {open && (
                <Paper style={{ position: 'fixed', bottom: 70, right: 20, width: 350, height: 450, padding: 10, display: 'flex', flexDirection: 'column' }}>
                    <List style={{ overflow: 'auto', flexGrow: 1 }}>
                        {messages.map((message, index) => (
                            <ListItem key={index} style={{
                                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                                background: message.sender === 'user' ? '#e0e0e0' : '#d258f1',
                                color: message.sender === 'user' ? '#000' : '#fff',
                                borderRadius: '20px',
                                margin: '5px',
                                padding: '10px'
                            }}>
                                <Avatar style={{ backgroundColor: 'transparent', marginRight: '5px' }}>
                                    {message.sender === 'user' ? <PersonIcon /> : <RobotIcon />}
                                </Avatar>
                                <ListItemText primary={message.text} />
                            </ListItem>
                        ))}
                    </List>
                    <Box component="form" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Ask a question..."
                            style={{ marginBottom: '10px' }}
                        />
                        <Button
                            variant="contained"
                            component="label"
                            style={{ marginBottom: '10px', backgroundColor: '#d258f1', color: '#fff' }}
                        >
                            Upload Image
                            <input
                                type="file"
                                hidden
                                onChange={handleImageChange}
                            />
                        </Button>
                        {image && <p>Image: {image.name}</p>}
                        <Button type="submit" style={{ backgroundColor: '#d258f1', color: '#fff' }}>Send</Button>
                    </Box>
                </Paper>
            )}
        </div>
    );
}

export default Chatbot;
