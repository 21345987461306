import React, { useEffect, useState } from "react";
import axios from "axios";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { scaleQuantize } from "d3-scale";
import { Box, Typography, CircularProgress, Paper } from "@mui/material";
import { styled } from "@mui/system";

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const MapContainer = styled(Box)({
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  padding: "10px",
  marginTop: "20px",
  width: "45%",  // Adjusted width for side-by-side placement
  maxWidth: "450px",  // Adjusted max-width for side-by-side placement
  height: "400px",  // Further reduced height
  margin: "0 auto",
  textAlign: "center",
});

const Tooltip = styled(Box)({
  background: "#fff",
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  position: "absolute",
  zIndex: "1000",
  pointerEvents: "none",
  boxShadow: "0 0 10px rgba(0,0,0,0.5)",
  textAlign: "center",
});

const Legend = styled(Paper)({
  padding: "10px",
  margin: "10px",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  borderRadius: "8px",
  textAlign: "left",
  position: "absolute",
  right: "20px",
  bottom: "20px",
  zIndex: "1000",
});

const stateNameMap = {
  "AL": "Alabama", "AK": "Alaska", "AZ": "Arizona", "AR": "Arkansas", "CA": "California",
  "CO": "Colorado", "CT": "Connecticut", "DE": "Delaware", "FL": "Florida", "GA": "Georgia",
  "HI": "Hawaii", "ID": "Idaho", "IL": "Illinois", "IN": "Indiana", "IA": "Iowa",
  "KS": "Kansas", "KY": "Kentucky", "LA": "Louisiana", "ME": "Maine", "MD": "Maryland",
  "MA": "Massachusetts", "MI": "Michigan", "MN": "Minnesota", "MS": "Mississippi", "MO": "Missouri",
  "MT": "Montana", "NE": "Nebraska", "NV": "Nevada", "NH": "New Hampshire", "NJ": "New Jersey",
  "NM": "New Mexico", "NY": "New York", "NC": "North Carolina", "ND": "North Dakota", "OH": "Ohio",
  "OK": "Oklahoma", "OR": "Oregon", "PA": "Pennsylvania", "RI": "Rhode Island", "SC": "South Carolina",
  "SD": "South Dakota", "TN": "Tennessee", "TX": "Texas", "UT": "Utah", "VT": "Vermont",
  "VA": "Virginia", "WA": "Washington", "WV": "West Virginia", "WI": "Wisconsin", "WY": "Wyoming"
};

const PatientDistributionMap = () => {
  const [data, setData] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [topStates, setTopStates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/api/patient_distribution`, {
        //const response = await axios.get("http://localhost:7000/api/patient_distribution", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const sortedData = Object.entries(response.data)
          .sort(([, a], [, b]) => b - a)
          .slice(0, 3);
        setData(response.data);
        setTopStates(sortedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching patient distribution data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const colorScale = scaleQuantize()
    .domain([0, Math.max(...Object.values(data || { CA: 0 }))])
    .range([
      "#f3e5f5",
      "#e1bee7",
      "#ce93d8",
      "#ba68c8",
      "#ab47bc",
      "#9c27b0",
      "#8e24aa",
      "#7b1fa2",
      "#6a1b9a",
      "#4a148c",
    ]);

  return (
    <MapContainer>
      <Typography
        variant="h5"
        gutterBottom
        style={{ color: "#d258f1", fontWeight: "bold", fontSize: "1.5rem", marginBottom: "10px" }}
      >
        Patient Distribution Map
      </Typography>
      
       {loading ? (
        <CircularProgress />
      ) : (
        data && (
          <Box position="relative" width="100%" height="100%">
            <ComposableMap projection="geoAlbersUsa" projectionConfig={{ scale: 700 }} width={600} height={400} data-tip="">
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const stateName = geo.properties.name;
                    const stateAbbr = Object.keys(stateNameMap).find(
                      key => stateNameMap[key] === stateName
                    );
                    const cur = data[stateAbbr] || 0;
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={colorScale(cur)}
                        onMouseEnter={() => {
                          setTooltipContent(`${stateName}: ${cur}`);
                        }}
                        onMouseLeave={() => {
                          setTooltipContent("");
                        }}
                        style={{
                          default: {
                            outline: "none",
                          },
                          hover: {
                            fill: "#d258f1",
                            outline: "none",
                          },
                          pressed: {
                            outline: "none",
                          },
                        }}
                      />
                    );
                  })
                }
              </Geographies>
            </ComposableMap>
            {tooltipContent && (
              <Tooltip style={{ left: "50%", top: "10%" }}>{tooltipContent}</Tooltip>
            )}
            <Legend>
              <Typography variant="h6" style={{ color: "#d258f1", fontWeight: "bold" }}>
                Top 3 States
              </Typography>
              {topStates.map(([state, count]) => (
                <Typography key={state} variant="body1" style={{ color: "#333" }}>
                  {stateNameMap[state]}: {count}
                </Typography>
              ))}
            </Legend>
          </Box>
        )
      )}
      
    </MapContainer>
  );
};

export default PatientDistributionMap;
